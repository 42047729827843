import React from "react"
import { graphql } from "gatsby"

import FourchetteIcon from "../components/svg/fourchette-icon"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { FadeInUp } from "../components/animations/fadeInUp"

class MentionsLegalesPage extends React.Component {

  render() {
    const { data, pageContext: { locale } } = this.props
    const content = data.file.childContentJson.cgu

    return (
      <Layout {...this.props}>
        <div className={"c-content"}>

          <SEO title="Mentions légales" />

          <section className="u-pd-vt-xl u-fadeInUp">
            <FadeInUp yOffset={50} delay={100} className="l-container u-pd-hz-l">
              <h1 className="c-h1 u-green80 u-uppercase u-semibold u-flex u-flex-center-vt u-mg-bottom-l">
                <FourchetteIcon className="u-mg-right-l" style={{ width: "60px", height: "15px" }}/>
                {content.title}
              </h1>
            </FadeInUp>
          </section>

          <section className="u-pd-hz-l">
            <div className="l-container">

                {content.content.map((item, index) => {
                    return (
                    <div className="u-mg-bottom-xl" key={"cgu-" + index}>
                        <h2 className="c-h2 u-uppercase u-mg-bottom-l">{item.title}</h2>
                        {item.desc.map((jtem, jndex) => {
                            return (
                            <div className="u-mg-bottom-l" key={"cgu-desc-" + jndex}>
                                <p className="u-fs-m u-lh-l u-mg-bottom-s">{jtem}</p>
                            </div>
                            )
                        })}
                    </div>
                    )
                })}

            </div>
          </section>


        </div>
      </Layout>
    )
  }
}

export default MentionsLegalesPage

export const pageQuery = graphql`
  query ($locale: String) {
    file(relativeDirectory: { eq: $locale }) {
      childContentJson {
        cgu {
            content {
              desc
              title
            }
            title
        }
        global {
          subscriptionLink
          header {
            candidate
            lang {
              en
              fr
            }
            navigation {
              accelerator
              english
              blog
              team
              program
              thematic
            }
          }
          footer {
            by
            cgu
            contact
            email
            press
            social {
              facebook
              instagram
              title
              twitter
              linkedin
            }
          }
          partners {
            name
            image
            link
          }
        }
      }
    },
    partnersLogo: allFile(
      filter: { absolutePath: { regex: "//partners//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
